import { useState, useEffect, useRef, useCallback } from 'react';
import { on, removeAllListeners } from '../../socket';
import ACTIONS from '../../utils/socketActions';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Card, Modal, Form, Input } from 'antd';
import { v4 } from 'uuid';
import { useHttp } from '../../hooks/http.hook';

export default function MainCallPage() {
  const { request } = useHttp();
  const history = useNavigate();
  const [rooms, updateRooms] = useState([]);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const rootNode = useRef();
  const [form] = Form.useForm();

  const fetchRooms = useCallback(async () => {
    const response = await request('api/calls/webrtc_getClientRooms');
    updateRooms(response.rooms);
  }, [updateRooms]);

  useEffect(() => {
    fetchRooms();
    on(ACTIONS.SHARE_ROOMS, async ({ rooms = [] } = {}) => {
      if (rootNode.current) {
        await fetchRooms();
      }
    });

    return () => {
      removeAllListeners(ACTIONS.SHARE_ROOMS);
    };
  }, []);

  const onFinish = async (values) => {
    const roomName = values.roomName;
    const v4gen = v4();
    history(`/room/${v4gen}/${roomName}`);
  };

  const NameModal = ({ text }) => {
    const handleOk = () => {
      form
        .validateFields()
        .then((values) => {
          form.resetFields();
          onFinish(values);
        })
        .catch((info) => {
          console.log('Validate failed: ', info);
        });
    };

    const handleCancel = () => {
      setIsNameModalOpen(false);
    };

    return (
      <Modal title={text} open={isNameModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Название "
            name="roomName"
            rules={[
              {
                required: true,
                message: 'Необходимо указать название группы!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div ref={rootNode}>
      <NameModal text={'Задайте название для группового звонка'} />
      <Button
        type="primary"
        style={{ margin: '20px 0 20px 20px' }}
        onClick={() => {
          setIsNameModalOpen(true);
        }}
      >
        Создать новый звонок
      </Button>
      <h1 style={{ marginLeft: '20px', marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>
        Доступные комнаты
      </h1>
      <div style={{ marginLeft: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {rooms.map((item) => (
          <div style={{ marginRight: '20px', backgroundColor: '#D0DEEC', borderRadius: '16.5px' }} key={item.roomID}>
            <Badge count={item.subscribersCount}>
              <div
                onClick={() => {
                  history(`/room/${item.roomID}/${item.roomLabel}`);
                }}
                width="100%"
                style={{
                  cursor: 'pointer',
                  width: '230px',
                  height: '170px',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    marginTop: '10px',
                    marginLeft: '10px',
                    width: '210px',
                    height: '125px',
                    backgroundColor: 'white',
                    borderRadius: '9px',
                  }}
                />
                <h2 style={{ marginTop: '12px', fontSize: '16px', fontWeight: 'bold' }}>{item.roomLabel}</h2>
              </div>
            </Badge>
          </div>
        ))}
      </div>
    </div>
  );
}
