import { useState, useEffect, useRef, useCallback } from 'react';
import { on, emit } from '../../socket';
import { Device } from 'mediasoup-client';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, message } from 'antd';
import {
  AudioOutlined,
  PhoneOutlined,
  DesktopOutlined,
  SettingOutlined,
  VideoCameraOutlined,
  MoreOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import ACTIONS from '../../utils/socketActions';
import styles from './GroupCallsPage.module.css';

export default function GroupCallsPage() {
  const history = useNavigate();
  const { id: roomName, fullRoomName } = useParams();
  const localVideo = useRef(null);
  const localMediaStream = useRef(null);
  const [users, setUsers] = useState([localVideo]);
  const device = useRef(null);
  const consumerTransports = useRef([]);
  const consumingTransports = useRef([]);
  const [screenShare, setScreenShare] = useState(false);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [fullScreenVideo, setFullScreenVideo] = useState(null);
  const [mirrorVideo, setMirrorVideo] = useState(false);
  const [facingMode, setFacingMode] = useState('user');

  const settingsItems = [
    {
      key: 'setReaction',
      label: 'Поставить реакцию',
    },
    {
      key: 'changeOutputDevice',
      label: 'Устройство вывода',
    },
    {
      key: 'changeInputDevice',
      label: 'Устройство ввода',
    },
    {
      key: 'mirrorScreen',
      label: 'Отразить видео',
    },
    {
      key: 'mainSettings',
      label: 'Общие настройки',
    },
  ];

  const videoButtonItems = [
    {
      key: 'fullscreen',
      label: 'На весь экран',
    },
  ];

  useEffect(() => {
    getLocalStream();
    return () => {
      if (localMediaStream.current) {
        localMediaStream.current.getTracks().forEach((track) => track.stop());
      }
      cleanupTransports();
      emit(ACTIONS.LEAVE);
      emit('pageUnmount', null);
    };
  }, []);

  let rtpCapabilities;
  const producerTransport = useRef({});
  let audioProducer;
  let videoProducer;

  const params = {
    encodings: [
      { rid: 'r0', maxBitrate: 100000, scalabilityMode: 'S1T3' },
      { rid: 'r1', maxBitrate: 300000, scalabilityMode: 'S1T3' },
      { rid: 'r2', maxBitrate: 900000, scalabilityMode: 'S1T3' },
    ],
    codecOptions: { videoGoogleStartBitrate: 1000 },
  };

  let audioParams;
  let videoParams = { params };

  const streamSuccess = async (stream) => {
    if (localVideo.current) {
      localVideo.current.srcObject = stream;
    }

    audioParams = { track: stream.getAudioTracks()[0], ...audioParams };
    videoParams = { track: stream.getVideoTracks()[0], ...videoParams };

    await joinRoom();
  };

  const joinRoom = async () => {
    emit('joinRoom', { roomName, fullRoomName }, async (data) => {
      rtpCapabilities = data.rtpCapabilities;
      await createDevice(rtpCapabilities);
    });
  };

  const getLocalStream = useCallback(async () => {
    try {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: { min: 640, max: 1920 }, height: { min: 400, max: 1080 } },
      });
      await streamSuccess(localMediaStream.current);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const createDevice = async (rtpCapabilities) => {
    try {
      device.current = new Device();

      await device.current.load({ routerRtpCapabilities: rtpCapabilities });
      console.log('Device RTP Capabilities', device.current.rtpCapabilities);
      await createSendTransport();
    } catch (error) {
      console.log(error);
      if (error.name === 'UnsupportedError') {
        console.warn('browser not supported');
      }
    }
  };

  const createSendTransport = async () => {
    emit('createWebRtcTransport', { consumer: false }, async ({ params }) => {
      if (params.error) {
        console.log(params.error);
        return;
      }
      const fetchRes = await fetchCredentials();
      producerTransport.current = device.current.createSendTransport({
        iceServers: [
          {
            urls: 'turn:turn.stormapi.su:3479',
            username: fetchRes.username,
            credential: fetchRes.password,
          },
        ],
        ...params,
      });

      producerTransport.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          emit('transport-connect', { dtlsParameters });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      producerTransport.current.on('produce', async (parameters, callback, errback) => {
        try {
          emit(
            'transport-produce',
            {
              kind: parameters.kind,
              rtpParameters: parameters.rtpParameters,
              appData: parameters.appData,
            },
            ({ id, producersExist }) => {
              callback({ id });
              if (producersExist) getProducers();
            }
          );
        } catch (error) {
          errback(error);
        }
      });

      await connectSendTransport(producerTransport);
    });
  };

  const fetchCredentials = async () => {
    const response = await fetch('/api/turnInfo/getCredentials', {
      method: 'GET',
      headers: {
        apikey: process.env.REACT_APP_TURN_SECRET,
      },
    });
    return await response.json();
  };

  const connectSendTransport = async (producerTransport) => {
    audioProducer = await producerTransport.current.produce(audioParams);
    videoProducer = await producerTransport.current.produce(videoParams);

    audioProducer.on('trackended', () => console.log('audio track ended'));
    audioProducer.on('transportclose', () => console.log('audio transport closed'));

    videoProducer.on('trackended', () => console.log('video track ended'));
    videoProducer.on('transportclose', () => console.log('video transport closed'));
  };

  const signalNewConsumerTransport = async (remoteProducerId, id, label) => {
    if (consumingTransports.current.includes(remoteProducerId)) return;
    consumingTransports.current.push(remoteProducerId);

    emit('createWebRtcTransport', { consumer: true }, async ({ params }) => {
      if (params.error) {
        console.log(params.error);
        return;
      }

      let consumerTransport;
      try {
        const fetchRes = await fetchCredentials();
        consumerTransport = device.current.createRecvTransport({
          iceServers: [
            {
              urls: 'turn:turn.stormapi.su:3479',
              username: fetchRes.username,
              credential: fetchRes.password,
            },
          ],
          ...params,
        });
      } catch (error) {
        console.log(error);
        return;
      }

      consumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log(`connected consumer Transport`);
        try {
          emit('transport-recv-connect', { dtlsParameters, serverConsumerTransportId: params.id });
          callback();
        } catch (error) {
          errback(error);
        }
      });

      await connectRecvTransport(consumerTransport, remoteProducerId, params.id, id, label);
    });
  };

  const getProducers = () => {
    emit('getProducers', null, (producerIds) => {
      producerIds.forEach(({ producerId, id, label }) => signalNewConsumerTransport(producerId, id, label));
    });
  };

  const connectRecvTransport = async (consumerTransport, remoteProducerId, serverConsumerTransportId, id, label) => {
    emit(
      'consume',
      {
        rtpCapabilities: device.current.rtpCapabilities,
        remoteProducerId,
        serverConsumerTransportId,
      },
      async ({ params }) => {
        if (params.error) {
          console.log('Cannot Consume');
          return;
        }

        const consumer = await consumerTransport.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters,
        });

        // Убедитесь, что consumer корректно создается
        if (consumer) {
          consumerTransports.current = [
            ...consumerTransports.current,
            { consumerTransport, serverConsumerTransportId: params.id, producerId: remoteProducerId, consumer },
          ];

          // Добавляем нового пользователя в список
          const newElem = { producerId: `td-${remoteProducerId}`, kind: params.kind, id, label };
          setUsers((prev) => {
            return [...prev, newElem];
          });

          // Запускаем поток
          emit('consumer-resume', { serverConsumerId: params.serverConsumerId });
        }
      }
    );
  };

  const cleanupTransports = () => {
    if (producerTransport.current) {
      producerTransport.current.close();
    }
    if (audioProducer) {
      audioProducer.close();
    }
    if (videoProducer) {
      videoProducer.close();
    }
    consumerTransports.current.forEach(({ consumerTransport, consumer }) => {
      consumerTransport.close();
      consumer.close();
    });

    consumerTransports.current = [];
  };

  on('new-producer', ({ producerId, id, label }) => signalNewConsumerTransport(producerId, id, label));

  on('producer-closed', ({ remoteProducerId }) => {
    const producerToClose = consumerTransports.current.find(
      (transportData) => transportData.producerId === remoteProducerId
    );
    if (producerToClose) {
      producerToClose.consumerTransport.close();
      producerToClose.consumer.close();
    }
    consumerTransports.current = consumerTransports.current.filter(
      (transportData) => transportData.producerId !== remoteProducerId
    );
    setUsers((prev) => prev.filter((user) => user.producerId !== `td-${remoteProducerId}`));
  });

  const provideMediaRef = useCallback((userId, videoElement) => {
    if (!videoElement) return;
    const transportData = consumerTransports.current.find(
      (transportData) => `td-${transportData.producerId}` === userId
    );

    if (transportData && transportData.consumer) {
      videoElement.srcObject = new MediaStream([transportData.consumer.track]);
      console.log(`Media assigned to video element for user: ${userId}`);
    } else {
      console.log(`No media found for user: ${userId}`);
    }
  }, []);

  // Обработчики нажатия на кнопки

  const handleVideoButtonClick = (e, peer) => {
    switch (e.key) {
      case 'fullscreen':
        setFullScreenVideo(peer);
        break;
    }
  };

  const handleCameraClick = () => {
    const videoTrack = localMediaStream.current.getVideoTracks()[0];
    if (videoTrack) {
      videoTrack.enabled = !cameraOn;
      setCameraOn(!cameraOn);
    }
  };

  const handleAudioClick = () => {
    const audioTrack = localMediaStream.current.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !audioOn;
      setAudioOn(!audioOn);
    }
  };

  const handleStopCallClick = () => {
    if (localMediaStream.current) {
      localMediaStream.current.getTracks().forEach((track) => track.stop());
    }
    cleanupTransports();
    emit(ACTIONS.LEAVE);
    history('/call_page');
  };

  const handleScreenClick = async () => {
    setScreenShare(!screenShare);
  };

  const toggleFacingMode = async () => {
    if (!localMediaStream.current) return;

    const videoTrack = localMediaStream.current.getVideoTracks()[0];
    const currentConstraints = videoTrack.getConstraints();

    try {
      videoTrack.stop();
      const newFacingMode = currentConstraints.facingMode === 'user' ? 'environment' : 'user';
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: newFacingMode,
        },
      });
      const newVideoTrack = newStream.getVideoTracks()[0];
      for (const producer of producerTransport.current._producers.values()) {
        if (producer.kind === 'video') {
          await producer.replaceTrack({ track: newVideoTrack });
          break;
        }
      }

      localMediaStream.current.removeTrack(videoTrack);
      localMediaStream.current.addTrack(newVideoTrack);

      if (localVideo.current) {
        localVideo.current.srcObject = localMediaStream.current;
      }
    } catch (error) {
      message.error('Ошибка переключения камеры: ' + error.message);
      console.error('Ошибка переключения камеры:', error);
    }
  };

  const handleSettingsClick = (e) => {
    switch (e.key) {
      case 'setReaction':
        console.log('Поставить реакицию');
        break;
      case 'changeOutputDevice':
        console.log('Устройство вывода');
        break;
      case 'changeInputDevice':
        console.log('Устройство ввода');
        break;
      case 'mirrorScreen':
        setMirrorVideo(!mirrorVideo);
        break;
      case 'mainSettings':
        console.log('Общие настройки');
        break;
    }
  };

  return (
    <div className={styles.container}>
      <h1 style={{ margin: '20px 0 20px', textAlign: 'center', fontSize: '24px', fontWeight: 'bold' }}>
        Комната: {fullRoomName}
      </h1>
      {!fullScreenVideo && (
        <div
          className={`${
            users.length === 1 ? styles.oneVideo : users.length === 3 ? styles.twoVideos : styles.videoGrid
          }`}
        >
          {users.map((peer, index) => {
            return (
              <div
                key={index + 1}
                className={styles.videoItem}
                id={peer.producerId}
                style={{
                  display: peer.kind === 'audio' && 'none',
                  border: peer === localVideo ? '3px solid rgb(152, 202, 255)' : '3px solid #c6c6c6',
                }}
              >
                <video
                  style={{ transform: mirrorVideo && peer === localVideo && 'scaleX(-1)' }}
                  ref={
                    peer === localVideo
                      ? localVideo
                      : (instance) => {
                          provideMediaRef(peer.producerId, instance);
                        }
                  }
                  muted={peer === localVideo}
                  autoPlay
                  playsInline
                />
                <div className={styles.userName}>{peer.label ? peer.label : 'Вы'}</div>
                {peer !== localVideo && (
                  <Dropdown
                    placement="bottomRight"
                    menu={{
                      items: videoButtonItems,
                      onClick: (e) => handleVideoButtonClick(e, peer),
                    }}
                    trigger={['click']}
                  >
                    <div className={styles.videoButton}>
                      <MoreOutlined style={{ fontSize: '21px' }} />
                    </div>
                  </Dropdown>
                )}
              </div>
            );
          })}
        </div>
      )}
      {fullScreenVideo && (
        <div className={styles.fullScreenVideoGrid}>
          <div
            className={styles.fullScreenVideoItem}
            onClick={() => {
              getLocalStream();
              setFullScreenVideo(null);
            }}
          >
            <video
              ref={(instance) => {
                provideMediaRef(fullScreenVideo.producerId, instance);
              }}
              muted={fullScreenVideo === localVideo}
              autoPlay
              playsInline
              className={styles.fullScreenVideo}
            />
            <video
              ref={(instance) => {
                provideMediaRef(
                  users.find((peer) => peer.id === fullScreenVideo.id && peer.kind === 'audio').producerId,
                  instance
                );
              }}
              autoPlay
              playsInline
              className={styles.fullScreenVideo}
              style={{ display: 'none' }}
            />
          </div>
        </div>
      )}
      <div className={styles.fixedButtons}>
        <div className={`${styles.button} ${styles.mobileOnly}`} onClick={toggleFacingMode}>
          <RetweetOutlined />
        </div>
        <div
          style={{ backgroundColor: cameraOn ? 'green' : 'gray' }}
          className={styles.button}
          onClick={() => handleCameraClick()}
        >
          <VideoCameraOutlined />
        </div>
        <div
          style={{ backgroundColor: audioOn ? 'green' : 'gray' }}
          className={styles.button}
          onClick={() => handleAudioClick()}
        >
          <AudioOutlined />
        </div>
        <div className={`${styles.button} ${styles.endCall}`} onClick={() => handleStopCallClick()}>
          <PhoneOutlined style={{ fontSize: '30px' }} rotate={-135} />
        </div>
        <div
          style={{ backgroundColor: screenShare ? 'green' : 'gray' }}
          className={styles.button}
          onClick={() => handleScreenClick()}
        >
          <DesktopOutlined />
        </div>
        <Dropdown
          placement="topLeft"
          menu={{
            items: settingsItems,
            onClick: (e) => handleSettingsClick(e),
          }}
          trigger={['click']}
        >
          <div className={`${styles.button} ${styles.settings}`}>
            <SettingOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
